export enum UrlParams {
    search = "search",
    city = "city",
    longitude = "lng",
    latitude = "lat",
    archiveId = "archive-id",
    archiveIdSave = "archive-id-save",
    organizerId = "organizer-id",
    orderId = "order-id",
    orderInvId = "order-invoice-id",
    workshopId = "workshop-id",
    type = "type",
    payment = "payment",
    updatePayment = "update-payment",
    badgeCheckinKey = "badge-checkin-key",
    studentOrganizerId = "usc-id",
    buyerOrganizerId = "user-buyer-id",
    buyerOrganizerId2 = "buyer-organizer-id",
    action = "action",
    publicId = "public-id",
    popupConnexion = "popup-connexion",
    popupOpen = "popup-open",
    isNew = "is-new",
    version = "version",
    paymentOnPlaceId = "payment-on-place-id",
    paymentOnPlacePublicId = "payment-on-place-public-id",
    productId = "product-id",
    productType = "product-type",
    deeIdFirst = "dee-id",
    popup = "popup",
    admin = "admin",
    createWorkshop = "create-workshop",
    planningTypeShow = "planning-type-show",
    updateWorkshop = "update-workshop",
    toolWaiting = "waiting-tool",
    searchTable = "search-table",
    setupIntentClientSecret = "setup_intent_client_secret",
    previous = "previous",
    setupIntent = "setup_intent",
    paymentIntent = "payment_intent",
    ics = "ics",
    tab = "tab",
    get = "get",
  }
  
  type UrlParamsKey = keyof typeof UrlParams;
  export class ClassUrl {
    static getUrlParams(urlParam: UrlParamsKey) {
      const queryString = window.location.search;
      const urlParams = new URLSearchParams(queryString);
  
      const value = urlParams.has(UrlParams[urlParam]) ? urlParams.get(UrlParams[urlParam]) || false : false;
  
      if (urlParam === "archiveId" && (value === "false" || !value)) {
        return "0";
      }
  
      return value;
    }
  }
  
  export class BaseUrlConstructor {
    getBaseOrganizertUrlParams() {
      throw new Error("Method not implemented.");
    }
    static getBaseOrganizertUrl(orgaId: number, url: string, action = "", archiveId:any = ClassUrl.getUrlParams("archiveId")) {
      let hrefPaymentOnPlacePage = "";
  
      hrefPaymentOnPlacePage += "/dashboard/" + url + "/?organizer-id=";
      hrefPaymentOnPlacePage += orgaId;
      hrefPaymentOnPlacePage += "&archive-id=";
      hrefPaymentOnPlacePage += archiveId;
  
      if (action !== "") {
        hrefPaymentOnPlacePage += "&action=" + action;
      }
  
      return hrefPaymentOnPlacePage;
    }
  
    static getBaseOrganizertUrlParam(orgaId: number, url: string, param = "") {
      let hrefPaymentOnPlacePage = "";
  
      hrefPaymentOnPlacePage += "/dashboard/" + url + "/?organizer-id=";
      hrefPaymentOnPlacePage += orgaId;
  
      if (param !== "") {
        hrefPaymentOnPlacePage += param;
      }
  
      return hrefPaymentOnPlacePage;
    }
  
    /*
     * ORDER
     */
    static getBaseContractUrl(orgaId: number, orderId: number, url: string, action = "") {
      let hrefPaymentOnPlacePage = "";
  
      hrefPaymentOnPlacePage += "/dashboard/order/" + url + "/?organizer-id=";
      hrefPaymentOnPlacePage += orgaId;
      hrefPaymentOnPlacePage += "&order-id=";
      hrefPaymentOnPlacePage += orderId;
  
      if (action !== "") {
        hrefPaymentOnPlacePage += "&action=" + action;
      }
  
      return hrefPaymentOnPlacePage;
    }
  
    static dashboardOrderContract(orgaId: number, orderId: number, url = "order-contract", action="") {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, url, action);
    }
  
    static dashboardPayment(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "order-payments", "");
    }
  
    static dashboardSpecialpurchase(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "outil-reservation-contrat");
    }
  
    static dashboardAmendment(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "order-contract");
    }
  
    static dashboardOrderItem(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "order-items-contract");
    }
  
    static dashboardUnpaidFile(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "order-payments");
    }
    static dashboardFormatPrice(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "outils-organisateur-prix", "price-links-page");
    }
  
    static dashboardOrderContact(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "&action=order-contract-page");
    }
  
    static discountContract(orgaId: number, orderId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, orderId, "outil-reservation-contrat", "order-contract-page");
    }
  
    /*
     * BUYER
     */
    static getBaseUrlBuyerOrganizer(orgaId: number, buyerOrganizerId: number, url: string, applyPopArchiveId=true) {
      let hrefPaymentOnPlacePage = "";
  
      hrefPaymentOnPlacePage += "/dashboard/" + url + "/?organizer-id=";
      hrefPaymentOnPlacePage += orgaId;
      hrefPaymentOnPlacePage += "&buyer-organizer-id=";
      hrefPaymentOnPlacePage += buyerOrganizerId;
      if(applyPopArchiveId)
      hrefPaymentOnPlacePage += "&archive-id=" + ClassUrl.getUrlParams("archiveId") || 0;
  
      return hrefPaymentOnPlacePage;
    }
  
    static dashboardBuyerPaymentOnPlaceCreation(orgaId: number,buyerOrganizerId: number,archiveId = ClassUrl.getUrlParams("archiveId") || 0,action = "", applyPopArchiveId=true) {
      let url = BaseUrlConstructor.getBaseUrlBuyerOrganizer(orgaId, buyerOrganizerId, "payment-on-place", applyPopArchiveId);
  
      url += "&archive-id=" + archiveId;
  
      if (action !== "") {
        url += "&action=" + action;
      }
      return url;
    }
  
    static buyerOrganizerCard(orgaId: number, buyerOrganizerId: number) {
      return BaseUrlConstructor.getBaseUrlBuyerOrganizer(orgaId, buyerOrganizerId, "buyer-organizer-card");
    }
  
    static dashboardBuyerBookingOnPlaceCreation(orgaId: number, buyerOrganizerId: number) {
      return BaseUrlConstructor.getBaseUrlBuyerOrganizer(orgaId, buyerOrganizerId, "/daily/inscription");
    }
  
    static dashboardBuyerSearchAdd(orgaId: number) {
      return BaseUrlConstructor.getBaseUrlBuyerOrganizer(orgaId, 0, "buyer-organizer-search");
    }
  
    static UrlPageContract(orgaId: number, buyerOrganizerId: number) {
      return BaseUrlConstructor.getBaseContractUrl(orgaId, buyerOrganizerId, "order-contract-page");
    }
  
    /*
     * WORKSHOP
     */
    static getBaseUrlWorkshop(orgaId: number, workshopId: number, url: string, action = "") {
      let hrefFormatWorkshop = "";
  
      hrefFormatWorkshop += "/dashboard/" + url + "/?organizer-id=";
      hrefFormatWorkshop += orgaId;
      hrefFormatWorkshop += "&workshop-id=";
      hrefFormatWorkshop += workshopId;
      if (action !== "") {
        hrefFormatWorkshop += "&action=" + action;
      }
  
      return hrefFormatWorkshop;
    }
  
    static UrlFormatWorkshop(orgaId: number, workshopId: number) {
      return BaseUrlConstructor.getBaseUrlWorkshop(
        orgaId,
        workshopId,
        "workshop-creation-modification",
        "setting-format"
      );
    }
  
    static UrlDatesWorkshop(orgaId: number, workshopId: number) {
      return BaseUrlConstructor.getBaseUrlWorkshop(orgaId, workshopId, "outil-dates");
    }
  
    static UrlParticipantsWorkshop(orgaId: number, workshopId: number) {
      return BaseUrlConstructor.getBaseUrlWorkshop(orgaId, workshopId, "outil-participants");
    }
  
    static UrlQuantityWorkshop(orgaId: number, workshopId: number) {
      return BaseUrlConstructor.getBaseUrlWorkshop(
        orgaId,
        workshopId,
        "workshop-creation-modification",
        "setting-quantity"
      );
    }
  
    /*
     * STUDENT ORGANIZER
     */
    static getBaseUrlStudentOrganizer(orgaId: number, studentOrganizerId: number, url: string) {
      let hrefPaymentOnPlacePage = "";
  
      hrefPaymentOnPlacePage += "/dashboard/" + url + "/?organizer-id=";
      hrefPaymentOnPlacePage += orgaId;
      hrefPaymentOnPlacePage += "&usc-id=";
      hrefPaymentOnPlacePage += studentOrganizerId;
  
      return hrefPaymentOnPlacePage;
    }
  
    static StudentOrganizerField(orgaId: number, studentOrganizerId: number) {
      return BaseUrlConstructor.getBaseUrlStudentOrganizer(orgaId, studentOrganizerId, "student-organizer-card");
    }
  
    static getBaseUrl(url: string, action = "") {
      let hrefPage = "";
  
      hrefPage += "/dashboard/" + url + "/?organizer-id=";
      hrefPage += ClassUrl.getUrlParams("organizerId");
  
      if (action !== "") {
        hrefPage += "&action=" + action;
      }
      return hrefPage;
    }
  
    // STRUCTURE
    static dashboardStructure(organizerTitle: string) {
      let hrefPaymentOnPlacePage = "";
      hrefPaymentOnPlacePage += "organisateurs-ateliers-cours-artistiques/" + organizerTitle + "/";
      return hrefPaymentOnPlacePage;
    }
  }
  